import React from 'react'
import bannerlarge1 from '../images/bannerlarge1.jpeg'
import bannerlarge2 from '../images/bannerlarge2.jpeg'
import banner1 from '../images/banner1.jpeg'
import banner2 from '../images/banner2.jpeg'

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// Import Swiper styles

import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const Hero = () => {



  return (

    <div className='relative '>


      <div className=' mt-[82px] hidden lg:flex sliderWrapper -z-10' >

        <Swiper 
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          style={{
                      "--swiper-navigation-color": "#000",
                      "--swiper-navigation-size": "14px",
                      "--swiper-navigation-weight": "700",
                    }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper -z-10  "
        >
          <SwiperSlide>
            <div className=' w-screen   -z-10 ' >
            <img loading='lazy' src={banner1} alt='' className='w-screen object-contain'/>
             
            </div>

          </SwiperSlide>
          <SwiperSlide>
            <div className=' w-screen   -z-10 ' >
            <img loading='lazy' src={banner2} alt='' className='w-screen object-contain'/>
             
            </div>

          </SwiperSlide>
        
           </Swiper>
        
      </div>
      <div className=' mt-[82px] sliderWrapper lg:hidden visible -z-10'>
      <Swiper 
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          style={{
            "--swiper-pagination-color": "white",
  "--swiper-pagination-bullet-inactive-color": "#999999",
  "--swiper-pagination-bullet-inactive-opacity": "1",
                    }}
          pagination={true}
          navigation={false}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper -z-10 lg
          :hidden block "
        >
          <SwiperSlide>
            <div className='  w-screen   -z-10 ' >
            <img loading='lazy' src={bannerlarge1} alt='' className=' w-full object-cover '/>
             
            </div>

          </SwiperSlide>
          <SwiperSlide>
            <div className='  w-screen   -z-10 ' >
            <img loading='lazy' src={bannerlarge2} alt='' className='  w-full object-cover '/>
             
            </div>

          </SwiperSlide>
        
           </Swiper>
      </div>

    </div>
  )
}

export default Hero
