import React from 'react'


import bracelete2 from '../images/jewels/bracelets2.jpg'
import bracelete3 from '../images/jewels/bracelets3.jpg'
import bracelate from '../images/jewels/bracelets1.jpg'
import bracelate4 from '../images/jewels/bracelets4.jpg'
import bracelate5 from '../images/jewels/bracelets5.jpg'
import bracelate6 from '../images/jewels/bracelets6.jpg'
import bracelateBg from '../images/braceletsBg.jpg'


const bracelets1 = [
    {
        id: 1,
        name: 'bracelets 1',
        url: bracelate
    },
    {
        id: 2,
        name: 'bracelets 2',
        url: bracelete2
    },
    {
        id: 3,
        name: 'bracelets 3',
        url: bracelete3
    },
    {
        id: 4,
        name: 'bracelets 3',
        url: bracelate4
    },
    {
        id: 5,
        name: 'bracelets 3',
        url: bracelate5
    },
    {
        id: 6,
        name: 'bracelets 3',
        url: bracelate6
    },

]

const Bracelets = () => {
    return (
        <>

            <div className='mt-20'>
                <img src={bracelateBg} loading='lazy' className='lg:h-80 h-40 w-screen object-cover aspect-square' />
                <div className='flex flex-col items-center justify-center lg:w-1/5 w-4/5  mx-auto lg:-mt-32 mt-4 z-10'>

                    <p className='text-[30px] px-2 tracking-wide  bg-white text-[#6e0d1d]' style={{ fontFamily: "Playball, cursive" }}>BRACELETES</p>
                    <p className=' text-center text-[14px] tracking-widest p-2 my-4 font-light bg-white' style={{ fontFamily: "Lexend Deca, sans-serif", lineHeight: "2" }}>A bracelet is an article of jewellery that is worn around the wrist. Bracelets may serve different uses, such as being worn as an ornament.</p>
                </div>
            </div>
            <div className='lg:w-[90%] lg:px-0 px-4  mx-auto'>
                <div className='flex flex-wrap justify-between items-center'>


                    {bracelets1?.map(ring => (
                        <div key={ring.id} className=' bg-white overflow-hidden shadow-lg  my-3 rounded-xl'>
                            <div className='overflow-hidden rounded-xl transition ease-in-out delay-150 duration-300 cursor-pointer'>
                                <img src={ring.url} loading='lazy' className=' lg:h-[283px] hover:scale-110 lg:w-[283px] w-[175px] h-[206px]' alt='' />
                            </div>
                            {/* <p className='text-center my-4'>{ring.name}</p> */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Bracelets
