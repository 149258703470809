import React,{useState,useLayoutEffect} from 'react'
import line from '../images/Line-Design.svg'
import '../css/asss.css'
// import ring from '../images/jewels/ring1.jpg'
import necklace from '../images/jewels/necklace1.jpg'
import bracelate from '../images/jewels/bracelets2.jpg'
import ring from '../images/jewels/ring6.jpg'
import earring from '../images/jewels/earring1.jpg'
import pendants from '../images/jewels/pendants1.jpg'
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className='text-2xl  flex flex-row-reverse  '>
            <button onClick={onClick} className='w-16 shadow-md rounded-full absolute top-[35%] z-10 p-1 cursor-pointer bg-white text-black'>
                &rarr;
            </button>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className='text-2xl  flex    '>

            <button onClick={onClick} className='w-16 shadow-md rounded-full absolute top-[35%] z-10 p-1 cursor-pointer bg-white text-black'>
                &larr;
            </button>
        </div>
    );
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

const MainData = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    useLayoutEffect(() => {
        function handleResize() {
          setWindowDimensions(getWindowDimensions());
        }
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    var settings = {
        className: "center",
        centerMode: true,
        infinite: true,
          centerPadding: "10px",
        slidesToShow: 4,
        lazyLoad: true,
        speed: 500,
        nextArrow:  <SampleNextArrow />,
        prevArrow:  <SamplePrevArrow />,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,
                    cssEase: "linear",
                    arrows: false,
                }
            }
        ]
    }
    
    return (
        <div className='bg-[#fffdfd] text-center text-black pt-14 pb-4 lg:px-10 px-2   z-20  h-auto' >
            <p className='lg:text-[64px] text-[30px] text-[#6e0d1d] font-semibold'>Best of Our Company</p>
            <img src={line} className='lg:-mt-10' alt='' />

            <div className='text-[#3c3c3c] mx-auto mt-6' style={{ fontFamily: "Lexend Deca, sans-serif" }}>


                <Slider {...settings} >

                    
                    <div className='box-border '>
                        <img loading="lazy" src={ring} className='rounded-xl h-[170px] w-[170px] lg:h-[290px] lg:min-w-[290px] ' alt='' />
                        <p className='mt-[24px] text-sm lg:text-xl ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Ring</p>

                    </div>
                    <div className='box-border'>
                        <img loading="lazy" src={bracelate} className='rounded-xl h-[170px] w-[170px] lg:h-[290px] lg:min-w-[290px] ' alt='' />
                        <p className='mt-[24px] text-sm lg:text-xl ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Bracelet</p>

                    </div>
                    <div className='box-border'>
                        <img loading="lazy" src={earring} className='rounded-xl h-[170px] w-[170px] lg:h-[290px] lg:min-w-[290px] ' alt='' />
                        <p className='mt-[24px] text-sm lg:text-xl ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Earrings</p>

                    </div>
                    <div className='box-border'>
                        <img loading="lazy" src={necklace} className='rounded-xl h-[170px] w-[170px] lg:h-[290px] lg:min-w-[290px] ' alt='' />
                        <p className='mt-[24px] text-sm lg:text-xl ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Necklace</p>

                    </div>
                    <div className='box-border'>
                        <img loading="lazy" src={pendants} className='rounded-xl h-[170px] w-[170px] lg:h-[290px] lg:min-w-[290px] ' alt='' />
                        <p className='my-[24px] text-sm lg:text-xl ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Pendant</p>

                    </div>

                </Slider>


            </div>
        </div>

    )
}

export default MainData
