import React from 'react'
import pendantsBg from '../images/pendantsBg.jpg'
import pendants from '../images/jewels/pendants1.jpg'
const pendants1 =   [
    {
        id: 1,
        name: 'pendants 1',
        url: pendants
    }
]
const Pendants = () => {
  return (
    <>
    <div className='mt-20'>
        <img src={pendantsBg} loading='lazy' className='lg:h-80 h-40 w-screen object-cover aspect-square bg-center' />
        <div className='flex flex-col items-center justify-center lg:w-1/5 w-4/5  mx-auto lg:-mt-32 mt-4 z-10'>
            <p className='text-[30px] px-2 tracking-wide  bg-white text-[#6e0d1d]' style={{ fontFamily: "Playball, cursive" }}>PENDANT</p>
            <p className=' text-center text-[14px] tracking-widest p-2 my-4 font-light bg-white' style={{ fontFamily: "Lexend Deca, sans-serif", lineHeight: "2" }}>Pendants are among the oldest recorded types of bodily adornment. Stone, shell, pottery, and more perishable materials were used.</p>
        </div>
    </div>
    <div className='lg:w-[90%] lg:px-0 px-4  mx-auto'>
    <div className='flex flex-wrap justify-between items-center'>

    
    {pendants1?.map(ring => (
                <div key={ring.id} className=' bg-white overflow-hidden shadow-lg  my-3 rounded-xl'>
                    <div className='overflow-hidden rounded-xl transition ease-in-out delay-150 duration-300 cursor-pointer'>
                        <img src={ring.url} loading='lazy' className=' lg:h-[283px] hover:scale-110 lg:w-[283px] w-[175px] h-[206px]' alt='' />
                    </div>
                    {/* <p className='text-center my-4'>{ring.name}</p> */}
                </div>
            ))}
</div>
    </div>
    </>
  )
}

export default Pendants
