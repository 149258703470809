import React from 'react'
import necklace from '../images/jewels/necklace1.jpg'
import necklace2 from '../images/jewels/necklace2.jpg'
import necklace3 from '../images/jewels/necklace3.jpg'
import necklace4 from '../images/jewels/necklace4.jpg'
import necklace5 from '../images/jewels/necklace5.jpg'
import necklace6 from '../images/jewels/necklace6.jpg'

const necklaces=[
    {
        id: 1,
        name: 'necklace 1',
        url: necklace
    },
    {
        id: 2,
        name: 'necklace 1',
        url: necklace2
    },
    {
        id: 3,
        name: 'necklace 1',
        url: necklace3
    },
    {
        id: 4,
        name: 'necklace 1',
        url: necklace4
    },
    
    {
        id: 5,
        name: 'necklace 1',
        url: necklace5
    },
    
    {
        id: 6,
        name: 'necklace 1',
        url: necklace6
    },
]

const Necklace = () => {
  return (
    <>

    <div className='mt-20'>
        <img src={necklace} loading='lazy' className='lg:h-80 h-40 w-screen object-cover aspect-square' />
        <div className='flex flex-col items-center justify-center lg:w-1/5 w-4/5  mx-auto lg:-mt-32 mt-4 z-10'>

            <p className='text-[30px] px-2 tracking-wide  bg-white text-[#6e0d1d]' style={{ fontFamily: "Playball, cursive" }}>NECKLACE</p>
            <p className=' text-center text-[14px] tracking-wide p-2 my-4 font-light bg-white' style={{ fontFamily: "Lexend Deca, sans-serif", lineHeight: "2" }}>Chokar necklace is a very fancy piece of ornaments that's gained quient an attention in recent times as fashion jewellery.</p>
        </div>
    </div>
    <div className='lg:w-[90%] lg:px-0 px-4  mx-auto'>
    <div className='flex flex-wrap justify-between items-center'>

    
    {necklaces?.map(ring => (
                <div key={ring.id} className=' bg-white overflow-hidden shadow-lg  my-3 rounded-xl'>
                    <div className='overflow-hidden rounded-xl transition ease-in-out delay-150 duration-300 cursor-pointer'>
                        <img src={ring.url} loading='lazy' className=' lg:h-[283px] hover:scale-110 lg:w-[283px] w-[175px] h-[206px]' alt='' />
                    </div>
                    {/* <p className='text-center my-4'>{ring.name}</p> */}
                </div>
            ))}
</div>
    </div>
    </>
  )
}

export default Necklace
