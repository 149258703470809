import React, { useState } from 'react'
import ringBg from '../images/ringBg.jpg'


import ring4 from '../images/jewels/ring4.jpg'
import ring5 from '../images/jewels/ring5.jpg'
import ring6 from '../images/jewels/ring6.jpg'
import ring7 from '../images/jewels/ring7.jpg'
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import logo from '../images/logo.PNG'


const rings = [
    {
        id: 1,
        name: 'Ring 1',
        url: ring4
    },
    {
        id: 2,
        name: 'Ring 2',
        url: ring5
    },
    {
        id: 3,
        name: 'Ring 3',
        url: ring6
    },
    {
        id: 4,
        name: 'Ring 3',
        url: ring7
    },


]


const Ring = () => {

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        // Update the state when the image is loaded
        setImageLoaded(true);
    };

    return (
        <>

            <div className='mt-20'>
                <img src={ringBg} loading='lazy' className='lg:h-80 h-40 w-screen object-cover aspect-square' />
                <div className='flex flex-col items-center justify-center  lg:w-1/5 w-4/5  mx-auto lg:-mt-32 mt-4 z-10'>

                    <p className='text-[30px] px-2  tracking-wide  bg-white text-[#6e0d1d]' style={{ fontFamily: "Playball, cursive" }}>Ring</p>
                    <p className=' text-center text-[14px] tracking-widest p-2 my-4 font-light bg-white' style={{ fontFamily: "Lexend Deca, sans-serif", lineHeight: "2" }}>Rings is brighton,every piece of ring is created around you .Ring are symboll of love and something you buy for your loved and special once</p>
                </div>
            </div>
            <div className='lg:w-[90%] lg:px-0 px-4  mx-auto'>
                <div className='flex flex-wrap justify-between items-center'>

                    {/* {imageLoaded && <p className='fixed top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] bg-white bg-opacity-50 w-[20%] z-10 ' ><Stack sx={{ width: '100%', color: 'grey.500' }} className='' spacing={2}>
                    <div className="backdrop-filter backdrop-saturate-180 backdrop-blur-20">
                        <img src={logo} alt='Nk jewels'/>
                        </div>
                        <LinearProgress color="secondary" />
                    </Stack></p>} */}

                    {rings?.map(ring => (
                        <div key={ring.id} className=' bg-white overflow-hidden shadow-lg  my-3 rounded-xl'>
                            <div className='overflow-hidden rounded-xl transition ease-in-out delay-150 duration-300 cursor-pointer'>
                                <img src={ring.url} onLoad={handleImageLoad} loading='lazy' className=' lg:h-[283px] hover:scale-110 lg:w-[283px] w-[175px] h-[206px]' alt='' />
                            </div>
                            {/* <p className='text-center my-4'>{ring.name}</p> */}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default Ring
