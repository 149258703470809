import React from 'react'
import Hero from './Hero'
import MainData from './MainData'
import Connect from './Connect'


const Home = () => {
  return (
    <div>
      <Hero/>
      <MainData/>
      <Connect/>
    </div>
  )
}

export default Home
