import React from 'react'
import promise1 from '../images/promise1.webp'
import promise2 from '../images/promise2.svg'
import promise3 from '../images/promise3.svg'
import promise4 from '../images/promise4.svg'
import personality1 from '../images/our-personality-one.png'
import personality3 from '../images/our-personality-three.webp'
import star2 from '../images/2stars.png'
import star3 from '../images/3stars.png'
import 'swiper/css';
import 'swiper/css/pagination';
// Import Swiper styles
import Slider from "react-slick";

import 'swiper/css/navigation';




const About = () => {
    const settings = {
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear"
    };
    return (
        <div className='bg-[#fef8f9] mt-[82px]'>
            <div className='text-[#6e0d1d] relative text-center py-14 '>
                <img src={star3} alt='' className='absolute lg:left-[75%]  lg:top-24 left-[60%] -top-12' />


                <p className='text-base'>Our</p>
                <p className='lg:text-[64px] text-[30px]'>Big Brand Idea</p>
                <div className='w-full cursor-pointer px-2 lg:px-10 py-8 mx-auto lg:flex   justify-between '>
                    <div className='lg:flex hidden text-white mx-auto group relative hover:bg-[#fccdd6] hover:text-[#d14a61] p-10 bg-[#d14a61] rounded-2xl mt-[60px] h-[240px] w-[380px]  flex-col items-center justify-center   ' style={{ transform: "rotate(-18deg)" }}>

                        <div className='absolute  -left-5  -top-3 ' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400" className=''>
                                <path d="M245.412 69.4217C244.647 63.9774 239.625 60.192 234.183 60.9569L234.167 60.8484C239.61 60.0835 243.393 55.0607 242.628 49.6164L242.744 49.6002C243.509 55.0445 248.53 58.8299 253.973 58.065L253.988 58.1735C248.545 58.9384 244.762 63.9612 245.527 69.4055L245.412 69.4217Z" fill="currentColor" />
                                <path d="M272.939 96.5356C271.256 84.558 260.208 76.2303 248.235 77.9131L248.201 77.6744C260.175 75.9916 268.499 64.9414 266.815 52.9638L267.069 52.9282C268.752 64.9058 279.8 73.2335 291.773 71.5507L291.807 71.7895C279.833 73.4722 271.509 84.5224 273.193 96.5L272.939 96.5356Z" fill="currentColor" />
                            </svg>
                        </div>
                            <h2 className='text-[50px] mb-4 flex' >01</h2>

<p className='text-xl'>Reflecting the attitute of our customers</p>
                    </div>    
                    <div className='text-white mx-auto group relative hover:bg-[#fccdd6] hover:text-[#d14a61] p-10 bg-[#d14a61] rounded-2xl -mb-4 h-[220px] w-[340px] lg:hidden flex flex-col items-center justify-center   ' style={{ transform: "rotate(-6deg)" }}>

                        <div className='absolute  -left-5  -top-3 ' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400" className=''>
                                <path d="M245.412 69.4217C244.647 63.9774 239.625 60.192 234.183 60.9569L234.167 60.8484C239.61 60.0835 243.393 55.0607 242.628 49.6164L242.744 49.6002C243.509 55.0445 248.53 58.8299 253.973 58.065L253.988 58.1735C248.545 58.9384 244.762 63.9612 245.527 69.4055L245.412 69.4217Z" fill="currentColor" />
                                <path d="M272.939 96.5356C271.256 84.558 260.208 76.2303 248.235 77.9131L248.201 77.6744C260.175 75.9916 268.499 64.9414 266.815 52.9638L267.069 52.9282C268.752 64.9058 279.8 73.2335 291.773 71.5507L291.807 71.7895C279.833 73.4722 271.509 84.5224 273.193 96.5L272.939 96.5356Z" fill="currentColor" />
                            </svg>

                        </div>

                        <h2 className='text-[50px] mb-4 flex' >01</h2>

                        <p className='text-xl'>Reflecting the attitute of our customers</p>
                    </div>
                    <div className='relative text-[#6e0d1d] mx-auto p-10 bg-[#fccdd6] hover:bg-[#d14a61] hover:text-white  rounded-2xl  
                    lg:h-[240px] lg:w-[380px] h-[220px] w-[340px] flex flex-col items-center justify-center ' >
                        <div className='absolute    top-3 ' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
                                <path xmlns="http://www.w3.org/2000/svg" d="M240.617 47.1641C240.617 41.6662 236.171 37.2189 230.676 37.2189V37.1093C236.171 37.1093 240.617 32.6619 240.617 27.1641H240.734C240.734 32.6619 245.18 37.1093 250.676 37.1093V37.2189C245.18 37.2189 240.734 41.6662 240.734 47.1641H240.617Z" fill="currentColor" />
                                <path xmlns="http://www.w3.org/2000/svg" d="M264.102 77.8359C264.102 65.7406 254.321 55.9565 242.23 55.9565V55.7154C254.321 55.7154 264.102 45.9313 264.102 33.8359H264.358C264.358 45.9313 274.139 55.7154 286.23 55.7154V55.9565C274.139 55.9565 264.358 65.7406 264.358 77.8359H264.102Z" fill="currentColor" />
                            </svg>

                        </div>
                        <h2 className='text-[50px] mb-4 flex' >02</h2>

                        <p className='text-xl'>Match an individuals need to expressherself unbashedly </p>
                    </div>

                    <div className='relative mx-auto text-white p-10 hover:bg-[#fccdd6] hover:text-[#d14a61] bg-[#d14a61] rounded-2xl mt-[60px] h-[240px] w-[380px] lg:flex hidden flex-col items-center justify-center ' style={{ transform: "rotate(18deg)" }}>

                        <div className='absolute -left-9  -top-6 ' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
                                <path xmlns="http://www.w3.org/2000/svg" d="M267.034 80.7359C267.799 75.2915 264.016 70.2687 258.573 69.5038L258.589 69.3953C264.031 70.1602 269.052 66.3749 269.818 60.9305L269.933 60.9467C269.168 66.3911 272.951 71.4139 278.394 72.1788L278.379 72.2873C272.936 71.5224 267.915 75.3077 267.15 80.7521L267.034 80.7359Z" fill="currentColor" />
                                <path xmlns="http://www.w3.org/2000/svg" d="M286.02 114.381C287.703 102.404 279.379 91.3536 267.406 89.6709L267.439 89.4322C279.413 91.1149 290.46 82.7872 292.143 70.8096L292.397 70.8453C290.714 82.8229 299.038 93.873 311.011 95.5558L310.978 95.7945C299.004 94.1117 287.957 102.439 286.274 114.417L286.02 114.381Z" fill="currentColor" />
                            </svg>

                        </div>
                        <h2 className='text-[50px] mb-4 flex' >03</h2>

                        <p className='text-xl'>Journey of self discovery and self expression</p>
                    </div>
                    <div className='relative mx-auto text-white p-10 hover:bg-[#fccdd6] hover:text-[#d14a61] bg-[#d14a61] rounded-2xl -mt-4 h-[220px] w-[340px] lg:hidden flex flex-col items-center justify-center ' style={{ transform: "rotate(6deg)" }}>

                        <div className='absolute -left-9  -top-6 ' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
                                <path xmlns="http://www.w3.org/2000/svg" d="M267.034 80.7359C267.799 75.2915 264.016 70.2687 258.573 69.5038L258.589 69.3953C264.031 70.1602 269.052 66.3749 269.818 60.9305L269.933 60.9467C269.168 66.3911 272.951 71.4139 278.394 72.1788L278.379 72.2873C272.936 71.5224 267.915 75.3077 267.15 80.7521L267.034 80.7359Z" fill="currentColor" />
                                <path xmlns="http://www.w3.org/2000/svg" d="M286.02 114.381C287.703 102.404 279.379 91.3536 267.406 89.6709L267.439 89.4322C279.413 91.1149 290.46 82.7872 292.143 70.8096L292.397 70.8453C290.714 82.8229 299.038 93.873 311.011 95.5558L310.978 95.7945C299.004 94.1117 287.957 102.439 286.274 114.417L286.02 114.381Z" fill="currentColor" />
                            </svg>

                        </div>
                        <h2 className='text-[50px] mb-4 flex' >03</h2>

                        <p className='text-xl'>Journey of self discovery and self expression</p>
                    </div>
                </div>
            </div>
            <div className='text-[#6e0d1d] text-center relative py-10'>
                <img src={star2} alt='' className='absolute -top-4 left-[25%] lg:-top-1 ' />
                <p className='text-base'>Our</p>
                <p className='lg:text-[64px] text-[30px]'>Personality</p>
                <div className='text-center  p-2 py-4  w-5/6 mx-auto lg:flex hidden justify-between'  >
                    <div className='max-h-[360px] max-w-[240px]'>

                        <img src={personality1} alt='' className=' mx-auto  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                        <p className='text-[#3c3c3c] font-semibold text-xl mb-4  '>Self-Expressive</p>
                        <p className='text-[#212121] font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Always stand up for what
                            we believe is right
                        </p>
                    </div>
                    <div className='max-h-[360px] max-w-[240px]'>

                        <img src={personality3} alt='' className='mx-auto rounded-2xl  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                        <p className='text-[#3c3c3c] font-semibold text-xl mb-4  '>Sassy</p>
                        <p className='text-[#212121] font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>We are lively, bold and a
                            little feisty
                        </p>
                    </div>
                    <div className='max-h-[360px] max-w-[240px]'>

                        <img src={personality3} alt='' className='mx-auto  rounded-2xl  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                        <p className='text-[#3c3c3c] font-semibold text-xl mb-4  '>Ingenious</p>
                        <p className='text-[#212121] font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Original designs and well
                            suited for the purpose

                        </p>
                    </div>
                    <div className='max-h-[360px] max-w-[240px]'>

                        <img src={personality1} alt='' className='mx-auto max-h-[360px]  max-w-[240px] mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                        <p className='text-[#3c3c3c] font-semibold text-xl mb-4  '>Earthphillic</p>
                        <p className='text-[#212121] font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Because we have just this
                            one planet to live in
                        </p>
                    </div>
                </div>
                <div className='text-center p-2 py-4 mx-auto lg:hidden'  >
                    <Slider {...settings}>
                        <div className='relative text-center '>

                            <img src={personality1} alt='' className='h-[561px] w-[394px] rounded-3xl aspect-square object-cover mx-auto  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                            <p className='text-white absolute bottom-28 left-[15%] w-2/3 font-bold text-2xl mb-4  '>Self-Expressive</p>
                            <p className='text-white absolute bottom-16 left-[15%] w-2/3 font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Always stand up for what
                                we believe is right
                            </p>
                        </div>
                        <div className='relative text-center '>

                            <img src={personality3} alt='' className='h-[561px] w-[394px] rounded-3xl aspect-square object-cover mx-auto  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                            <p className='text-white absolute bottom-28 left-[15%] w-2/3 font-bold text-2xl mb-4  '>Sassy</p>
                            <p className='text-white absolute bottom-16 left-[15%] w-2/3 font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>We are lively, bold and a
                            little feisty
                            </p>
                        </div>
                        <div className='relative text-center '>

                            <img src={personality3} alt='' className='h-[561px] w-[394px] rounded-3xl aspect-square object-cover mx-auto  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                            <p className='text-white absolute bottom-28 left-[15%] w-2/3 font-bold text-2xl mb-4  '>Ingenious</p>
                            <p className='text-white absolute bottom-16 left-[15%] w-2/3 font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Original designs and well
                            suited for the purpose
                            </p>
                        </div>
                        <div className='relative text-center '>

                            <img src={personality1} alt='' className='h-[561px] w-[394px] rounded-3xl aspect-square object-cover mx-auto  mb-8 hover:rotate-6 transition ease-in-out delay-150 duration-300 cursor-pointer' />
                            <p className='text-white absolute bottom-28 left-[15%] w-2/3 font-bold text-2xl mb-4  '>Earthphillic</p>
                            <p className='text-white absolute bottom-16 left-[15%] w-2/3 font-normal text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Because we have just this
                            one planet to live in
                            </p>
                        </div>
                        
                        
                        
                    </Slider>
                </div>
            </div>


            <div className='text-[#6e0d1d] relative text-center lg:py-32 py-10'>
                <img src={star3} alt='' className='absolute  lg:left-[75%]  lg:top-24 left-[60%] -top-12' />

                <p className='text-base'>Our</p>
                <p className='lg:text-[64px] text-[30px]'>Promises</p>
                <div className='lg:w-4/5 w-full min-h-full mx-auto flex flex-wrap  lg:flex-nowrap py-4  px-3 lg:px-0  '>
                    <div className='  text-center p-2   lg:w-[300px] lg:h-[418px] min-h-full w-1/2 flex flex-col  lg:justify-start'>
                        <img src={promise1} alt='' className='mx-auto  mb-8' />
                        <p className='text-[#3c3c3c] font-bold lg:text-xl text-[15px] mb-4  '>Guaranteed Purity</p>
                        <p className='text-[#6e0d1d] font-normal text-[14px] lg:text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Always stand up for what
                            we believe is right

                        </p>
                    </div>
                    <div className='relative  text-center p-2 lg:w-[300px]  lg:h-[418px] min-h-full w-1/2 flex flex-col  lg:justify-end'>
                    <div className='relative'>

                        <img src={promise4} alt='' className='mx-auto  mb-8' />
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 absolute top-20 left-[50%]">
  <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
</svg>
                    </div>

                        <p className='text-[#3c3c3c] font-bold lg:text-xl text-[15px] mb-4'>Certified Product</p>
                        <p className='text-[#6e0d1d] font-normal text-[14px] lg:text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Because we have just this
                            one planet to live in</p>
                    </div>
                    <div className='  text-center lg:p-2 lg:pt-2 p-2 pt-8 lg:w-[300px]  lg:h-[418px] min-h-full w-1/2 flex flex-col  lg:justify-end'>
                        <img src={promise2} alt='' className='mx-auto  mb-8' />
                        <p className='text-[#3c3c3c] font-bold lg:text-xl text-[15px] mb-4 '>Transparent Billing</p>
                        <p className='text-[#6e0d1d] font-normal text-[14px] lg:text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>We are lively, bold and a
                            little feisty</p>
                    </div>
                    <div className='  text-center lg:p-2 lg:pt-2 p-2 pt-8 lg:w-[300px]  lg:h-[418px] min-h-full w-1/2 flex flex-col  lg:justify-start'>
                        <img src={promise3} alt='' className='mx-auto  mb-8' />
                        <p className='text-[#3c3c3c] font-bold lg:text-xl text-[15px] mb-4 '>Lifetime Maintenance</p>
                        <p className='text-[#6e0d1d] font-normal text-[14px] lg:text-base ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Original deisgns and well
                            suited for the purpose</p>
                    </div>

                </div>
                
            </div>

        </div>
    )
}

export default About
