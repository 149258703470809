import React from 'react'
import line from '../images/line-black.svg'
import { Link } from 'react-router-dom'
// import { Link } from 'react-router-dom'


const Connect = () => {
  return (
    <div className='bg-[#e6ded3] text-[#101926] bg-opacity-50 text-center p-10'>
       <p className='text-3xl font-semibold'>Connect With Us</p>
       <p className='lg:text-base text-[14px]'>We are always available to guide you at your convenience.</p>
            <img src={line} className='lg:-mt-4 ' alt='' />

            <ul className="  flex flex-wrap lg:flex-nowrap gap-4 items-center justify-around   rounded-md pb-3 pt-3 lg:px-10 px-2 " style={{ fontFamily: "Lexend Deca, sans-serif" }}>
                                <li className="text-left border border-[#C7B7A3] text-[13px] text-[#212529] font-normal rounded-xl w-[380px] px-4 pr-8 py-4" style={{ fontFamily: "Lexend Deca, sans-serif" }}>
                                    <Link to='https://api.whatsapp.com/send/?phone=+919978366966&text&type=phone_number&app_absent=0' target='_blank' className='cursor-pointer group'>

                                        <p className="block font-medium text-lg text-[#3C3633]  " style={{ fontFamily: "Lexend Deca, sans-serif" }}>Connect on</p>
                                        <p className='block font-bold text-xl text-[#3C3633]  mb-2 ' >Whatsapp </p>
                                        <p className=' flex items-center justify-between'>
                                            <span className='text-base text-[#747264]' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Connect</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#747264" className="w-8 h-8 group-hover:translate-x-3 transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </p>
                                    </Link>
                                </li>
                                <li className="text-left border border-[#C7B7A3] text-[13px] text-[#212529] font-normal rounded-xl w-[380px] px-4 pr-8 py-4">
                                    <Link to='/book-appoinment' className='group cursor-pointer'>
                                        <p className="block font-medium text-lg text-[#3C3633]  " style={{ fontFamily: "Lexend Deca, sans-serif" }}>Book an </p>
                                        <p className='block font-bold text-xl text-[#3C3633]  mb-2 '>Appoinment</p>
                                        <p className=' flex items-center justify-between'>
                                            <span className='text-base text-[#747264]' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Book</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#747264" className="w-8 h-8 group-hover:translate-x-3 transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </p>
                                    </Link>
                                </li>
                                <li className="text-left  border border-[#C7B7A3] text-[13px] text-[#212529] font-normal rounded-xl w-[380px] px-4 pr-8 py-4">
                                    <Link to='/book-appoinment' className='group cursor-pointer'>
                                        <p className="block font-medium text-lg text-[#3C3633]  " style={{ fontFamily: "Lexend Deca, sans-serif" }}>Schedule a</p>
                                        <p className='block font-bold text-xl text-[#3C3633]  mb-2 '>Video Call</p>
                                        <p className=' flex items-center justify-between'>
                                            <span className='text-base text-[#747264]' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Schedule</span>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#747264" className="w-8 h-8 group-hover:translate-x-3 transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                                        </p>
                                    </Link>
                                </li>

                            </ul>
    </div>
  )
}

export default Connect
