import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../css/main.css'
import logo from '../images/logo.PNG'
// import Button from '@mui/material/Button';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import Ring from '../images/Rings.webp';
import Necklace from '../images/Necklace.webp';
import Earrings from '../images/Earrings.webp';
import Pendant from '../images/Pendant.webp';
import Bracelet from '../images/Bracelet.webp';


// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#d14a61',
//     },
//     secondary: {
//       main: '#f44336',
//     },
//   },
// });


import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu, theme } from 'antd';
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const items = [
  getItem(<span className='' style={{}}>OUR PRODUCTS</span>, 'sub1', null, [
    getItem(<div className='flex text-black cursor-pointer  '>
      <Link to='/ring'>
        <span className=' text-base font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Rings</span>
      </Link>

    </div>, '1'),
    getItem(<div className='  cursor-pointer  '>
      <Link to='/earring'>
        <span className=' text-base font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Earrings</span>
      </Link>
    </div>, '2'),
    getItem(<div className='   cursor-pointer  '>
      <Link to='/necklace'>
        <span className=' text-base font-light ' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Necklaces</span>
      </Link>
    </div>, '3'),
    getItem(<div className='  cursor-pointer  '>
      <Link to='/bracelete'>
        <span className=' text-base font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Braceletes</span>
      </Link>
    </div>, '4'),
    getItem(<div className='  cursor-pointer  '>
      <Link to='/Pendant'>
        <span className=' text-base font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Pendants</span>
      </Link>
    </div>, '5'),
  ]),
  getItem(<Link to='/about-us'>ABOUT US</Link>, 'sub2'),
  getItem(<Link to='/book-appoinment'>CONTACT US</Link>, 'sub4'),
];


const Navbar = () => {
  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
  const [initialLoad, setInitialLoad] = useState(true);

  const location = useLocation()
  const menuRef = useRef(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isPathnameChanged, setIsPathnameChanged] = useState('/');


  const [openKeys, setOpenKeys] = useState(['sub1']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };


  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      // If not the initial load, toggle the menu based on location change
      setIsMenuVisible(false);
    }
  }, [location])


  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the clicked element is inside the menu
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Clicked outside the menu, close it
        setIsMenuVisible(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener('click', handleOutsideClick);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuRef]);

  return (
    <div className='relative'>


      <nav className=' fixed  z-30 h-20 bg-white   w-full top-0 border-b shadow-md text-[#101926] flex justify-between items-center pl-10 lg:pr-10 pr-8 py-2'>
        <Link to={"/"}>
          <div className='w-[190px] '>
            <img src={logo} alt='logo...' className='w-[100%] h-16 aspect-square object-contain' style={{  }} />
          </div>
        </Link>
        <div className='hidden lg:flex items-center justify-between gap-10 text-lg font-medium '>


          <div className={`group/parent  `} >
            <div className='flex relative items-center cursor-pointer'>

              <span className='navHover pb-1'>Collections</span>
              {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2  group-hover/parent:rotate-180 transition-all ease-in-out duration-300">
                <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
              </svg> */}
            </div>



            <div className="z-20  shadow-xl   group-hover/parent:block pt-6 -mt-1 transition ease-in-out delay-150 duration-3000   left-0 absolute    hidden h-auto w-screen">
              <div className=" border-t flex pt-4 items-center justify-around w-screen  rounded-md pb-4  h-[200px] overflow-hidden px-10 bg-white" style={{ fontFamily: "Lexend Deca, sans-serif" }}>
                <div className='bg-[#efefef] group rounded-xl border hover:-rotate-6 cursor-pointer py-2 text-center  transition ease-in-out  duration-200 w-32'>
                  <Link to='/ring'>

                    <img src={Ring} alt="" className='h-28 w-32 rounded-xl' style={{}} />
                    <div className='flex justify-around items-center transition-all ease-in-out duration-1000 delay-100' >
                    <p className=' text-[14px] font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Rings </p>
                  <div className='bg-white p-1 rounded-full hidden group-hover:flex'>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.8} stroke="black" className="w-6 h-6  transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                  </div>
                    </div>
                  </Link>
                </div>
                <div className='bg-[#efefef] group rounded-xl border hover:-rotate-6 cursor-pointer py-2 text-center  w-32 transition ease-in-out  duration-200'>
                  <Link to='/pendant'>

                    <img src={Pendant} alt="" className='h-28 w-32 rounded-xl' style={{}} />
                    <div className='flex justify-around items-center transition-all ease-in-out duration-1000 delay-100' >
                    <p className=' text-[14px] font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Pendants </p>
                  <div className='bg-white p-1 rounded-full hidden group-hover:flex'>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.8} stroke="black" className="w-6 h-6  transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                  </div>
                    </div>
                  </Link>
                </div>
                <div className='bg-[#efefef] group rounded-xl border hover:-rotate-6 cursor-pointer py-2 text-center  w-32 transition ease-in-out  duration-200'>
                  <Link to='/bracelete'>

                    <img src={Bracelet} alt="" className='h-28 w-32 rounded-xl' style={{}} />
                    <div className='flex justify-around items-center transition-all ease-in-out duration-1000 delay-100' >
                    <p className=' text-[14px] font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Bracelets </p>
                  <div className='bg-white p-1 rounded-full hidden group-hover:flex'>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.8} stroke="black" className="w-6 h-6  transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                  </div>
                    </div>
                  </Link>
                </div>
                <div className='bg-[#efefef] group rounded-xl border hover:-rotate-6 cursor-pointer py-2 text-center  w-32 transition ease-in-out  duration-200'>
                  <Link to='/necklace'>
                    <img src={Necklace} alt="" className='h-28 w-32 rounded-xl' style={{}} />

                    <div className='flex justify-around items-center transition-all ease-in-out duration-1000 delay-100' >
                    <p className=' text-[14px] font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Necklaces </p>
                  <div className='bg-white p-1 rounded-full hidden group-hover:flex'>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.8} stroke="black" className="w-6 h-6  transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                  </div>
                    </div>
                  </Link>
                </div>
                <div className='bg-[#efefef] rounded-xl group border hover:-rotate-6 cursor-pointer py-2 text-center  w-32 transition ease-in-out  duration-200'>
                  <Link to='/earring'>

                    <img src={Earrings} alt="" className='h-28 w-32 rounded-xl' style={{}} />
                    <div className='flex justify-around items-center transition-all ease-in-out duration-1000 delay-100' >
                    <p className=' text-[14px] font-light' style={{ fontFamily: "Lexend Deca, sans-serif" }}>Earrings </p>
                  <div className='bg-white p-1 rounded-full hidden group-hover:flex'>

                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={0.8} stroke="black" className="w-6 h-6  transition-all ease-in-out duration-300">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                                            </svg>
                  </div>
                    </div>
                  </Link>
                </div>



              </div>
            </div>
          </div>











          <div className='relative '><Link to='/about-us'><li className='pb-1 list-none text-lg font-medium navHover transition ease-in-out delay-150 duration-3000'>About us</li></Link></div>
          <Link to='/book-appoinment'><button className='bg-[#d14a61] text-white rounded-full px-4 py-2 hover:text-white  text-base font-medium  '>Contact us</button></Link>
        </div>



        <div className='lg:hidden flex' ref={menuRef}>
          <div onClick={toggleMenu}>

            <svg
              xmlns="http://www.w3.org/2000/svg"

              fill="#d14a61"
              className='h-[30px] w-[30px] cursor-pointer'
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="#d14a61"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>

          </div>

          {isMenuVisible && (
            <Menu
              className='absolute top-14 right-0 z-10'

              mode="inline"
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              style={{
                width: 256,
                backgroundColor: "white",
                borderRadius: "10px",
                border: ''

              }}
              items={items}
            />
          )}
        </div>






















        {/* <ThemeProvider theme={theme}>

          <Button size='small' sx={{}}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
           
          </Button>

          <Menu className='h-screen w-screen'
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}

            PaperProps={{sx:{width:'50vw',height:'100vh',padding:'20px',marginTop:"18px"}}}
          >
            <MenuItem  className="" ><p>Collections</p> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-2  group-hover/parent:rotate-180 transition-all ease-in-out duration-300">
  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
</svg>
            
            {/* <Link to='/earring'><MenuItem onClick={handleClose} className="">Earring</MenuItem></Link> */}

        {/* </MenuItem>
            <Link to='/about-us'  className='hidden' ref={m1}><MenuItem  onClick={handleClose} >About Us</MenuItem></Link>
            <Link to='/book-appoinment'  className='hidden' ref={m1}><MenuItem   onClick={handleClose} >Contact Us</MenuItem></Link>
            <Link to='/about-us'><MenuItem onClick={handleClose} className="">About Us</MenuItem></Link>
            <Link to='/book-appoinment'><MenuItem onClick={handleClose}>Contact Us</MenuItem></Link>

          </Menu> */}
        {/* </ThemeProvider>  */}


      </nav>






    </div>
  )
}

export default Navbar
