import React, { } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faXTwitter, faWhatsapp, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Link,useLocation } from 'react-router-dom';
import '../css/main.css'
import { faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
// import flag from '../images/flag.png'
import logo from '../images/logo.PNG'
import insta from '../images/insta.svg'
import facebook from '../images/facebook.svg'
import twitter from '../images/twitter.svg'
import sms from '../images/sms.svg'
import whatsapp from '../images/whatsapp.svg'
import email from '../images/email.svg'
import phone from '../images/phone.svg'


const Footer = () => {

  const location = useLocation()

  return (

    <>

      <div className={`pt-10 pb-1  lg:px-20 px-8 lg:w-[98%]  w-[96%] text-white ${location.pathname==='/book-appoinment'?'mt-10 mb-28 lg:my-4':'my-4'} -z-10  mx-auto flex-col rounded-t-[50px] rounded-b-[50px] `} style={{ background: "#d14a61 0% 0% no-repeat padding-box" }} >
        <div className=' flex justify-between   pb-6 border-b border-b-white/40'>
          <div className='text-left text-white'>
            <h1 className='lg:text-3xl text-xl  font-medium '>Information</h1>
            <ul className='mt-10  font-medium  text-[12px] lg:text-base' style={{ color: "", fontFamily: "" }}>
              <li className='mb-2   hover:text-white'><Link to='/about-us' className=' '>About Us</Link></li>

              <li className='mb-2  hover:text-white'><Link to='' className=''>Blog</Link></li>
              <li className='mb-2  hover:text-white '><Link to='/book-appionment' className=''></Link>Help & FAQs</li>
              <li className='mb-6  hover:text-white'><Link to='' className=''>Legal policies</Link></li>
            </ul>

          </div>
          <div className='text-left' >
            <h1 className='lg:text-3xl text-xl  font-medium  '>Contact Us</h1>
            <p className='mt-10  font-medium flex items-center gap-2 text-[12px] lg:text-base' > <Link to='tel:+919978366966' target='_blank' className=''>+91 9978366966</Link></p>
            <p className='mt-4  font-medium flex items-center gap-2 text-[12px] lg:text-base' > <Link to='tel:0261-3518296' target='_blank' className=''>0261-3518296</Link></p>
            <p className=' mt-4 font-medium flex items-center gap-2 text-[12px] lg:text-base' ><Link to='' className=''>Surat,Gujarat</Link></p>

            <p className='mt-4  font-medium flex items-center gap-2 text-[12px] lg:text-base' > <Link to='mailto:nkjewels330@gmail.com' className=''>nkjewels330@gmail.com</Link></p>
            <p className='mt-4  font-medium flex items-center gap-2 text-[12px] lg:text-base' > <Link to='https://api.whatsapp.com/send/?phone=+919978366966&text&type=phone_number&app_absent=0' target='_blank' className=''>Whatsapp chat with us</Link></p>


          </div>
        </div>

        <div className='flex items-center py-4 lg:justify-start lg:gap-10 justify-between border-b border-b-white/40'>
          <h1 className='lg:text-3xl text-xl  font-medium  '>Social</h1>
          <div className='gap-4 flex cursor-pointer'>

            <Link to='https://www.instagram.com/nkjewelsofficial?igsh=MTZ4b2Y3dGVzYjVnbg%3D%3D&utm_source=qr ' target='_blank'><img src={insta} alt='' /></Link>
            <Link to='https://www.facebook.com/people/NK-Jewels/100064120415010/?mibextid=LQQJ4d' target='_blank'><img src={facebook} alt='' /></Link>
            <Link to='https://www.twitter.com/' target='_blank'><img src={twitter} alt='' /></Link>
          </div>
        </div>
        <div className='flex lg:hidden items-center justify-between py-4 gap-10 border-b border-b-white/40'>
          <h1 className='text-xl  font-medium  '>Contact</h1>
          <div className='gap-4 flex cursor-pointer items-center '>

            <Link to='tel:+919978366966' target='_blank'><img src={phone} alt='' /></Link>
            <Link to='SMS:+919978366966' target='_blank'><img src={sms} alt='' /></Link>
            <Link to='mailto:nkjewels330@gmail.com' target='_blank'><img src={email} alt='' /></Link>
            <Link to='https://api.whatsapp.com/send/?phone=+919978366966&text&type=phone_number&app_absent=0' target='_blank'><img src={whatsapp} alt='' /></Link>
          </div>
        </div>
        <div className='py-4'>
        <p className=' text-end lg:text-[14px] text-[12px] font-semibold ' >
            &#169;2024 All rights reserved by <b>NK</b>JEWELS.</p>

        </div>
        
      </div></>

  )
}

export default Footer 
