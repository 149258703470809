import './App.css';
import Conatct from './components/Conatct';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import About from './components/about-us';
import { useEffect } from 'react';
import Earring from './components/earring';
import Ring from './components/ring';
import Necklace from './components/necklace';
import Braceletes from './components/bracelets';
import Pendant from './components/pendants';
import Loader from './components/loader';

function App() {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="App overflow-x-hidden ">
    

        <Navbar />
      
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/loader" element={<Loader/>}></Route>

        <Route exact path="/book-appoinment" element={<Conatct />}></Route>
        <Route exact path="/about-us" element={<About />}></Route>
        <Route exact path="/earring" element={<Earring/>}></Route>
        <Route exact path="/ring" element={<Ring/>}></Route>
        <Route exact path="/necklace" element={<Necklace/>}></Route>
        <Route exact path="/bracelete" element={<Braceletes/>}></Route>
        <Route exact path="/pendant" element={<Pendant/>}></Route>
      </Routes>
      <Footer/>
    

      {/* <Footer/> */}
    </div>
  );
}

export default App;
