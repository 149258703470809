import React from 'react'
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';

const loader = () => {
  return (
    <div>
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam hic placeat, eos fugiat ducimus debitis inventore consectetur! Nobis officiis dolorem architecto eaque similique voluptatibus dolore obcaecati. Alias aut error exercitationem!
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam hic placeat, eos fugiat ducimus debitis inventore consectetur! Nobis officiis dolorem architecto eaque similique voluptatibus dolore obcaecati. Alias aut error exercitationem!
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam hic placeat, eos fugiat ducimus debitis inventore consectetur! Nobis officiis dolorem architecto eaque similique voluptatibus dolore obcaecati. Alias aut error exercitationem!
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam hic placeat, eos fugiat ducimus debitis inventore consectetur! Nobis officiis dolorem architecto eaque similique voluptatibus dolore obcaecati. Alias aut error exercitationem!
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam hic placeat, eos fugiat ducimus debitis inventore consectetur! Nobis officiis dolorem architecto eaque similique voluptatibus dolore obcaecati. Alias aut error exercitationem!
       <Stack sx={{ width: '20%', color: 'grey.500' }} className='fixed top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]' spacing={2}>

      <LinearProgress color="secondary" />
     
     
    </Stack>
    </div>
  )
}

export default loader
